import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import HostelPage from './Pages/HostelPage';
import SearchPage from './Pages/SearchPage';
import BookingPage from './Pages/BookingPage';
import SuccessPage from './Pages/SuccessPage';
import Terms from './Pages/Terms';
import Refund from './Pages/Refund';
import Privacy from './Pages/Privacy';
import LandingPage from './Pages/LandingPage';
import Toolbar from './Components/Toolbar';

class App extends React.Component {
  render () {
    return (
      <div>
        <Toolbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/hostel" element={<HostelPage />} />
          <Route path='/search' element={<SearchPage />} />
          <Route path='/booking' element={<BookingPage  />} />
          <Route path='/success' element={<SuccessPage  />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/refund' element={<Refund />} />
          <Route path='/privacy' element={<Privacy />} />
        </Routes>
      </div>
    )
  }
}

export default App;
