import React from "react";

class Terms extends React.Component {
    render() {
        return (
            <div className="pt-5 p-xl-5 p-3">
                <h1 className="mt-5 mb-3">Terms and Conditions</h1>
                This site belongs to Shapalgo Products LLP. By using, accessing, viewing, transmitting,
                caching, storing or otherwise utilizing any of the services, contents, or functions of this site,
                you signify your consent to these terms and conditions as set forth herein. The terms and conditions
                set out below apply to your use of this web site and related web sites (“Site”), including the use of
                the information services offered on the Site. <br /><br />
                In accessing and using the Site, you agree to be bound by these Conditions so please carefully read
                this section before proceeding. If you do not agree to these terms of use, you must refrain from using the Site. <br /><br />
                We reserve the right, at our discretion, to change, modify, add, or remove portions of these terms
                at any time. Please check these terms periodically for changes. All changes will become effective
                immediately unless otherwise stated and you should check these terms for changes from time to time.
                Any messages, notes, suggestions, ideas, notes, drawings, concepts or other material or information
                (collectively, the “Submissions”), which you may submit through or in connection with the Site will
                become the exclusive property of us. Accordingly, you agree that we have the right to publish any Submissions
                for any purpose whatsoever, commercial or otherwise, without compensation to the provider of the Submissions. <br /><br />
                In addition, you agree that you will not transmit any Submission which infringes upon the rights of any third party.
                Neither us nor any of our subsidiaries, affiliates, agents, representatives or licensors shall be
                responsible for any direct, indirect, special, incidental or consequential loss or damage, punitive or similar
                damages including without limitation, loss or damage by way of loss of profits, loss of business opportunity,
                business interruption or loss of information however arising and whether in contract, tort or otherwise, which
                you or anyone else may suffer in connection with or arising out of, however arising and whether in contract,
                tort or otherwise, which you may suffer in connection with or arising out of your access or use or your
                inability to access or use of the Site or any Linked Website; or your use of or reliance on Information or
                any other material accessed through the Site including via any Linked Website; <br /> <br /> These Conditions do not purport
                to exclude liability arising by any applicable law if, and to the extent, such liability cannot be
                lawfully excluded, however, to the extent permitted by law, all warranties, terms or conditions which would
                otherwise be implied into these Conditions are hereby excluded. Where applicable law implies any warranty, term
                or condition, and that law prohibits exclusion or modification of the application of or the liability of Shapalgo
                Products LLP under, any such warranty, term or condition, then the liability of Shapalgo Products LLP shall include
                liability for that warranty, term or condition but such liability will be limited for a breach of that warranty,
                term or condition, if the breach relates to services, the supply of the services again or payment of the cost of
                having the services supplied again; and if the breach relates to products and the products can be repaired, repair
                of such products or payment of the costs of having the products repaired, but otherwise, replacement of the products
                or the supply of equivalent products or payment of the cost of replacing the products or of acquiring equivalent
                products. <br /><br /> We are not responsible for, and shall have no liability for:<br /> (i) telephone, mobile, electronic,
                network, internet, computer, hardware or software program malfunctions, delays, or failures;<br /> (ii) for any
                injury, loss or damage to your computer or interception or use of credit card information, related to or
                resulting from use of the site or any sites, services, or linked or relating thereto;<br /> (iii) any incorrect
                information, whether caused by other users or by any of the equipment, software, or programming associated with
                or utilized in the site or as the result of technical or human error which may occur in the processing of information
                egarding the site.

            </div>
        )
    }
}

export default Terms