import React from "react"

class Footer extends React.Component {
    render() {
        return (
            <div className="bg-dark-o p-xl-5 p-3">
                <div className="d-flex flex-md-row flex-column justify-content-between mb-5">
                    <div className="col-md-6 col-12 m-2">
                        <div className="h3 fw-bold text-primary-o">
                            About Us
                        </div>
                        <div className="col-12 col-xl-10 text-text">
                            TheHostelco aim to create a new Safe and Social Travel Experience -
                            Allowing a new generation of travellers to break the monotony: Go Off-Track, 
                            Meet new people and experience the magic of Travel
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="row row-cols-xl-2 row-cols-1 g-3">
                            <div className="col">
                                <div className="h3 fw-bold text-primary-o mb-3">Legal</div>
                                <div>
                                    <a className="text-text text-decoration-none" href="/privacy">Privacy Policy</a>
                                </div>
                                <div>
                                    <a className="text-text text-decoration-none" href="/terms">Terms & Conditions</a>
                                </div>
                                <div>
                                    <a className="text-text text-decoration-none" href="/refund">Refund Policy</a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="h3 fw-bold text-primary-o mb-3">Contact Us</div>
                                <div className="fw-bold text-primary-o">Email Address</div>
                                <div className="mb-3 text-text">palash@thehostelco.com</div>
                                <div className="fw-bold text-primary-o">Address</div>
                                <div className="mb-3 text-text">Shapalgo Products LLP 507, Golf Manor Apt Wind Tunnel Rd ,
                                     Murugeshpalaya , Bengaluru -560017</div>
                                <div className="fw-bold text-primary-o">Contact Number</div>
                                <div className="mb-3 text-text">+91 967 320 3092</div>
                                <div className="text-text">For any other queries, please reach out to us at the above number, email or address</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center text-text">
                    CopyRight <i className="bi bi-c-circle"></i> Shapalgo Products LLP
                </div>
            </div>
        )
    }
}

export default Footer
