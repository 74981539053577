import React from "react";
import Logo from "../Assets/Logo.svg"

class Toolbar extends React.Component {
    render () {
        return (
            <div className="px-5 py-3">
                <img src={Logo} alt="logo" />
            </div>
        )
    }
}

export default Toolbar