import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const [searchParams, setSearchParams] = useSearchParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, searchParams }}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter;