import React from "react";

class Refund extends React.Component {
    render() {
        return (
            <div className="pt-5 p-xl-5 p-3">
                <h1 className="mt-5 mb-3">Refund / Cancellation Policy</h1>
                Cancellation requests received more than 5 days before your check-in date will receive a 100% refund. <br /><br />
                Cancellation requests received within 5 days of the check-in date will not be eligible for a refund and the 
                entire advance amount will be retained as a cancellation fee. Bookings created within this 5-day period are 
                also non-refundable. Additionally, no date modifications (changes to dates or reduction of stay duration) are 
                allowed within this 5-day window and will be treated as cancellations. <br /><br />
                In case the property is not able to host you due to any local or subjective circumstances, 
                we will provide you with a credit of the same amount. You will be able to avail the credited amount for any 
                future booking at any of our properties.

            </div>
        )
    }
}

export default Refund