import React from "react";
import { Badge, Button, Card, CardBody, Input, Label } from "reactstrap";
import Footer from "../Components/Footer";
import withRouter from "../Components/withRouter";
import axios from "axios";
import { baseUrl } from "../index"
import Moment from "react-moment";

class HostelPage extends React.Component {
    constructor() {
        super();
        this.state = {
            checkInDate: "",
            checkOutDate: "",
            hostelId: 0,
            guests: "0",
            days: 0,
            hostel: [],
            inventory: []
        }
    }

    componentDidMount() {
        const { searchParams } = this.props.router
        this.setState({ hostelId: searchParams.get("hostel"), checkInDate: searchParams.get("from"), checkOutDate: searchParams.get("to") }, () => {
            const d1 = new Date(this.state.checkInDate);
            const d2 = new Date(this.state.checkOutDate);
            const timeDifference = Math.abs(d2 - d1);
            const differenceInDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            this.setState({ days: differenceInDays })
        })
        axios.get(`${baseUrl}/inventory/hostel/${searchParams.get("hostel")}`).then(response => {
            this.setState({ hostel: response.data })
        })
        axios.get(`${baseUrl}/inventory/hostel-inventory/${searchParams.get("hostel")}`).then(response => {
            const filterDate = new Date(searchParams.get("from"))
            const temp = response.data.filter(item => {
                const itemDate = new Date(item.date);
                return itemDate > filterDate &&
                    itemDate.getMonth() === filterDate.getMonth() &&
                    itemDate.getFullYear() === filterDate.getFullYear();
            });
            this.setState({ inventory: temp }, () => console.log(this.state.inventory))
        })
    }

    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onReserve = () => {
            const { checkInDate, checkOutDate, guests } = this.state
            console.log(this.props.router)
            window.location.href = `/booking?id=${this.props.router.searchParams.get("hostel")}&guests=${guests}&from=${checkInDate}&to=${checkOutDate}`
        }

        return (
            <div>
                <div className="mt-3 main-container">
                    <div className="d-flex gap-3 mb-3 justify-content-between flex-lg-row flex-column">
                        <div className="col-12 col-xl-8">
                            <div className="h4">
                                Entire rental unit hosted by {this.state.hostel[0]?.hostel_name}
                            </div>
                            <div className="d-flex gap-3 mb-3">
                                <div>
                                    <i className="bi bi-star me-1"></i> {this.state.hostel[0]?.rating}
                                </div>
                                <div className="text-decoration-underline">
                                    {this.state.hostel[0]?.total_reviews} reviews
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-xl-4 row-cols-lg-3 g-3">
                                {this.state.inventory.map(eachInventory => {
                                    return (
                                        <div className="col">
                                            <Card>
                                                <CardBody>
                                                    <div>
                                                        <Moment className="h5 mb-2" format="DD MMM">
                                                            {eachInventory.date}
                                                        </Moment>
                                                    </div>
                                                    <Badge className="mb-2" color="warning">
                                                        {eachInventory.roomType}
                                                    </Badge>
                                                    <div className="text-success">
                                                        {eachInventory.availableRooms - eachInventory.bookedRooms} rooms available
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>

                                    )
                                })}
                            </div>
                        </div>
                        <div>
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between gap-3 mb-5">
                                        <div className="d-flex gap-1 align-items-end">
                                            <div className="h4 mb-0">Rs. {this.state.hostel[0]?.price * 0.1}</div> / night
                                        </div>
                                        <div className="d-flex gap-3">
                                            <div>
                                                <i className="bi bi-star me-1"></i> {this.state.hostel[0]?.rating}
                                            </div>
                                            <div className="text-decoration-underline">
                                                {this.state.hostel[0]?.total_reviews} reviews
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-cols-lg-2 row-cols-1 g-3 mb-3">
                                        <div className="col">
                                            <Label className="fw-bold">CHECK-IN</Label>
                                            <Input placeholder="Check In Date" type="date" name="checkInDate" onChange={onChange} value={this.state.checkInDate} />
                                        </div>
                                        <div className="col">
                                            <Label className="fw-bold">CHECK-OUT</Label>
                                            <Input placeholder="Check Out Date" type="date" name="checkOutDate" onChange={onChange} value={this.state.checkOutDate} />
                                        </div>
                                    </div>
                                    <Label className="fw-bold">GUESTS</Label>
                                    <Input onChange={onChange} value={this.state.guests} name="guests" type="select" className="mb-3">
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(eachGuest => {
                                            return (
                                                <option value={eachGuest} key={eachGuest}>
                                                    {eachGuest}
                                                </option>
                                            )
                                        })}
                                    </Input>
                                    <Button onClick={onReserve} className="w-100 mb-3 bg-primary-o" size="lg" >
                                        Reserve
                                    </Button>
                                    <div className="text-light-gray text-center mb-3">
                                        You won't be charged yet
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div className="fw-bold">Rs. {this.state.hostel[0]?.price * 0.1} * {this.state.days} {this.state.days === 1 ? "day" : "days"}</div>
                                        <div>Rs. {this.state.hostel[0]?.price * this.state.days * 0.1}</div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div className="fw-bold">Taxes</div>
                                        <div className="">Rs. {this.state.hostel[0]?.price * this.state.days * 0.1 * 0.1}</div>
                                    </div>
                                    <hr className="hr" />
                                    <div className="d-flex justify-content-between mb-3">
                                        <div className="fw-bold">Total</div>
                                        <div className="fw-bold">Rs. {this.state.hostel[0]?.price * this.state.days * 0.1 + this.state.hostel[0]?.price * this.state.days * 0.1 * 0.1}</div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="d-flex gap-3 h5 fw-bold mb-5">
                        <div>
                            <i className="bi bi-star-fill me-1"></i> {this.state.hostel[0]?.rating}
                        </div>
                        <div className="text-decoration-underline">
                            {this.state.hostel[0]?.total_reviews} reviews
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-3 g-5 mb-4">
                        <div className="col">
                            <div className="d-flex gap-3 align-items-center mb-3">
                                <div>
                                    <img src={require("../Assets/Avatar.png")} alt="hostelco" />
                                </div>
                                <div>
                                    <div className="fw-bold">Hostel Co Expert Rating</div>
                                    <div className="text-light-gray">September 2024</div>
                                </div>
                            </div>
                            <div className="fw-bold">
                                Apt place for staycations with amentities like pool, bonfire
                            </div>
                        </div>
                    </div>
                    {/* <Button className="bg-secondary-o text-dark-o mb-5">
                        Show all {this.state.hostel[0]?.total_reviews} reviews
                    </Button> */}
                    <div className="h4 mb-3">Where you'll be</div>
                    <iframe src={this.state.hostel[0]?.location_url} title="maps"
                        width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className="fw-bold mb-3">
                        {this.state.hostel[0]?.hostel_name}, {this.state.hostel[0]?.location.charAt(0).toUpperCase() + this.state.hostel[0]?.location.slice(1).toLowerCase()}
                    </div>
                    <div className="col-lg-7 text-justify col-12 mb-3">
                        Very dynamic and appreciated district by the people of Bordeaux
                        thanks to rue St James and place Fernand Lafargue. Home to many historical
                        monuments such as the Grosse Cloche, the Porte de Bourgogne and the Porte Cailhau,
                        and cultural sites such as the Aquitaine Museum.
                    </div>
                    {/* <div className="fw-bold text-decoration-underline mb-5">
                        Show More
                    </div> */}
                </div>
                <Footer />
            </div>
        )
    }
}

export default withRouter(HostelPage)