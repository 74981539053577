import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

export const baseUrl = process.env.NODE_ENV === "development" ? 'http://localhost:5000' : "https://zostel-backend-3tsc.onrender.com"

export const imageKitUrl = "https://ik.imagekit.io/fomod195d/"