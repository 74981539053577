export const citySearch = [
    { name: "Anjuna", value: "anjuna" },
    { name: "Calungate", value: "calungate" },
    { name: "Pondicherry", value: "pondicherry" },
    { name: "Kochi", value: "kochi" },
    { name: "Gokarna", value: "gokarna" }
]

export const couponCodes = [
    { name: "ZO50", value: 50 }
]