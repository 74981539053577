import React from "react";

class Privacy extends React.Component {
    render() {
        return (
            <div className="pt-5 p-xl-5 p-3">
                <h1 className="mt-5 mb-3">Privacy Policy</h1>
                Privacy policy is required by the law and this policy only applies to Shapalgoproduct LLP (thehostelco.com) 
                and not to the websites of other companies, individuals or organisations to whom we provide links to our websites. <br /><br />
                <div className="h4">Use of Your Information</div>
                We collect your information for the safety of our users who use our platform and 
                guests who book through thehostelco.com to understand who we are accommodating at our partner properties, 
                for the safety of staff. In addition, website user and guest data is collected for statistical purposes. 
                We also collect our guests’ nationality, date of birth, gender, for statistical analysis purposes. 
                When you visit our websites, we may automatically log your IP address 
                (the unique address which identifies your computer on the internet). 
                We use IP addresses to help us manage our websites and to collect broad demographic 
                information for analytical use. For reservations, we send guests confirmation emails and 
                will therefore require your email address. Exceptions may occur in the case of us needing to 
                contact previous guests in relation to post or lost property. <br /><br />
                <div className="h4">Reservation Data</div>
                In order for us to confirm a reservation for you, we do require some information. This will usually consist of:<br /><br />
                Your name<br />
                Telephone or mobile number – in case of an emergency <br />
                Gender <br />
                Nationality <br />
                Date of Birth <br />
                Identification data i.e. Passport, Driving License details <br />
                Credit card details, including the three-digit code that appears on the back of your credit card <br />
                Date of arrival and departure <br />
                Email address <br /><br />
                Upon arrival, we will require the same information from your fellow travellers, 
                please ensure they are all aware of this to ensure a quick and efficient check-in. <br /><br />
                <div className="h4">Website Security</div>
                The Internet is not a secure medium. However, we have put in place various security procedures, 
                including firewalls that are used to block unauthorized traffic to our website. <br /><br />
                <div className="h4">Third Party Websites</div>
                Our website contains links to many other websites promoting their business and needs to our guests. 
                thehostelco indemnifies itself against all data use on and reservations made via third party websites/agents. 
                Please refer to their relevant privacy policy and terms and conditions. <br /><br />
                <div className="h4">Disclosing Guests' Personal Information to Third Parties</div>
                Other than that for the purposes referred to in this policy, 
                we will not disclose any personal information without your permission unless we are 
                legally obliged to do so (for example, if required to do so by court order or for the purposes of prevention of fraud). <br /><br />
                <div className="h4">Changes to Our Privacy Policy</div>
                We may change our Privacy Policy at any time. 
                Continued use of our website signifies that you agree to any such changes
            </div>
        )
    }
}

export default Privacy