import firebase from "firebase/app"
import "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyC7-c_PWPMRBZaVdNsckZmO41co33CkPx4",
    authDomain: "hostelco-d76f4.firebaseapp.com",
    projectId: "hostelco-d76f4",
    storageBucket: "hostelco-d76f4.appspot.com",
    messagingSenderId: "43139805601",
    appId: "1:43139805601:web:146d42643bd5561adb9be0"
};

firebase.initializeApp(firebaseConfig)
export const auth = firebase.auth()

export default firebase