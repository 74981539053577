import React from "react";
import { Label, Input, Button, Card, CardBody } from "reactstrap";
import Footer from "../Components/Footer"
import withRouter from "../Components/withRouter"
import axios from "axios";
import { baseUrl } from "../index"
import { citySearch } from "../Config/constants";
import SelectSearch from "react-select-search";
import "../App.css"

class SearchPage extends React.Component {
    constructor() {
        super()
        this.state = {
            fromDate: "",
            toDate: "",
            searchInput: "",
            allHostels: []
        }
    }

    componentDidMount() {
        const { searchParams } = this.props.router
        this.setState({ searchInput: searchParams.get("city"), fromDate: searchParams.get("from"), toDate: searchParams.get("to") }, () => {
            const { searchInput, fromDate, toDate } = this.state
            axios.get(`${baseUrl}/inventory/search-results?location=${searchInput}&checkInDate=${fromDate}&checkOutDate=${toDate}`).then(response => {
                this.setState({ allHostels: response.data })
            }).catch(err => {
                console.log(err.message)
            })
        })
    }

    render() {
        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onModify = () => {
            const { searchInput, fromDate, toDate } = this.state
            window.location.href = `/search?city=${searchInput}&from=${fromDate}&to=${toDate}`
        }

        const onHostel = (id) => {
            const { fromDate, toDate } = this.state
            window.location.href = `/hostel/?hostel=${id.id}&from=${fromDate}&to=${toDate}`
        }

        return (
            <div>
                <div className="main-container mt-5">
                    <div className="d-flex gap-3 align-items-end mb-5 flex-wrap">
                        <SelectSearch options={citySearch} name="searchInput" search onChange={(value) => { this.setState({ searchInput: value }) }} placeholder="Search City/Name" />
                        <div>
                            <Label className="text-dark-o">Check In</Label>
                            <Input onChange={onChange} value={this.state.fromDate} placeholder="From" name="fromDate" type="date" />
                        </div>
                        <div>
                            <Label className="text-dark-o">Check Out</Label>
                            <Input onChange={onChange} value={this.state.toDate} placeholder="To" name="toDate" type="date" />
                        </div>
                        <Button onClick={onModify} className="bg-dark-o border-0">
                            Modify
                        </Button>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-2 g-3 mb-3">
                        {/* <div className="col-12 col-lg-3">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between gap-3 mb-3">
                                        <div className="h5 mb-0 text-dark-o">Hostel Filter</div>
                                        <div style={{ cursor: "pointer" }} className="text-decoration-underline text-primary-o">Reset all</div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div> */}
                        <div className="col-12 col-lg-9">
                            {this.state.allHostels.length === 0 &&
                                <div>
                                    No hostels are available
                                </div>}
                            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 g-3 mb-5">
                                {this.state.allHostels.map(eachCard => {
                                    return (
                                        <div key={eachCard} className="col">
                                            <Card onClick={() => onHostel(eachCard)} className="bg-text-o border-o" style={{ cursor: "pointer" }}>
                                                <CardBody>
                                                    <div className="mb-3">
                                                        <img src={`https://ik.imagekit.io/fomod195d/${eachCard.id}/Thumbnail.png`} alt="hostelco" className="w-100" />
                                                    </div>
                                                    <div className="text-light-gray mb-3">
                                                        {eachCard.roomType}
                                                    </div>
                                                    <div className="h4 text-dark-o mb-2">
                                                        {eachCard.hostel_name}
                                                    </div>
                                                    <div className="text-dark-o mb-3">
                                                        {eachCard.description}
                                                    </div>
                                                    <div className="d-flex gap-4 mb-3 text-dark-o">
                                                        <div className="fw-bold">
                                                            <i className="bi bi-star-fill me-1"></i> {eachCard.rating}
                                                        </div>
                                                        <div>
                                                            {eachCard.total_reviews} reviews
                                                        </div>
                                                    </div>
                                                    <div className="d-flex gap-3 justify-content-between text-dark-o">
                                                        <div className="fw-bold">
                                                            <i className="bi bi-geo-alt me-1"></i> {eachCard.location}
                                                        </div>
                                                        <div>
                                                            <div className="h5 fw-bold">Rs. 5,900</div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default withRouter(SearchPage)