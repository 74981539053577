import React from "react";

class SuccessPage extends React.Component {
    render() {
        const goToHome = () => {
            localStorage.removeItem("success")
            window.location.href = "/"
        }
        return (
            <div className="text-center main-container">
                <img src={require("../Assets/success.gif")} className="mb-3 mt-5" style={{ width: "600px"}} alt="academic" />
                <div className="h3">Congratulations !! Your booking is confirmed</div>
                <div className="mb-3">A confirmation email has been sent to your registered email.</div>
                <div className="mb-5">
                    <a href="/" className="text-decoration-none text-secondary" onClick={goToHome}>Click Here</a> to go to HomePage
                </div>
            </div>
        )
    }
}

export default SuccessPage