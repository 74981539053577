import React from "react"
import Landing from "../Assets/HomeLanding.png"
import { Button, Card, CardBody, Input } from "reactstrap"
import 'react-select-search/style.css'
import SelectSearch from "react-select-search"
import { citySearch } from "../Config/constants"
import Hostel from "../Assets/Hostel.png"
import Tickets from "../Assets/Tickets.png"
import MapImage from "../Assets/MapImage.png"
import Luggage from "../Assets/Luggage.png"
import Footer from "../Components/Footer"
import axios from "axios"
import { baseUrl } from "../index"

class LandingPage extends React.Component {
    constructor() {
        super()
        this.state = {
            searchInput: "",
            fromDate: "",
            toDate: "",
            allHostels: []
        }
    }
    componentDidMount() {
        axios.get(`${baseUrl}/inventory/all-hostels`).then(response => {
            this.setState({ allHostels: response.data })
        }).catch(err => {
            console.log(err.message)
        })
    }
    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onSubmit = () => {
            const { searchInput, fromDate, toDate } = this.state
            window.location.href = `/search?city=${searchInput}&from=${fromDate}&to=${toDate}`
        }

        const hostelRedirect = (id) => {
            window.location.href = `/hostel/?hostel=${id}`
        }

        return (
            <div style={{ backgroundColor: "#EEE9E5" }}>
                <div className="main-container">
                    <div className="text-dark fw-bold main-container py-5">
                        <div className="home-heading">
                            Redefining the <br /> <span className="text-primary-o">Hostel Experience</span>
                        </div>
                        {/* <Button>Book Now</Button> */}
                        <img className="home-image" style={{ width: "100%" }} src={Landing} alt="hostel" />
                        <div style={{ backgroundColor: "#FFFFFF", borderRadius: "2rem" }} className="px-5 py-4 mt-5">
                            <div className="row row-cols-xl-4 row-cols-1 g-3">
                                <div className="col">
                                    <div className="fw-bold text-dark">Where</div>
                                    <SelectSearch options={citySearch} name="searchInput" search onChange={(value) => { this.setState({ searchInput: value }) }} placeholder="Search Destination" />
                                </div>
                                <div className="col">
                                    <div className="fw-bold text-dark px-2">Check In</div>
                                    <Input className="border-0" onChange={onChange} value={this.state.fromDate} placeholder="From" name="fromDate" type="date" />
                                </div>
                                <div className="col">
                                    <div className="fw-bold text-dark">Check Out</div>
                                    <Input className="border-0" onChange={onChange} value={this.state.toDate} placeholder="To" name="toDate" type="date" />
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">
                                    <Button onClick={onSubmit} className="book-button">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="row row-cols-lg-3 g-3 row-cols-1 my-5">
                            {this.state.allHostels.map(eachHostel => {
                                return (
                                    <div key={eachHostel.id} className="col">
                                        <img style={{ borderRadius: "1.5rem", width: "100%" }} src={Hostel} alt="hostel" />
                                        <div className="h3 text-dark mt-2">{eachHostel.hostel_name}</div>
                                        <div className="text-dark fw-normal mb-2">
                                            {eachHostel.description}
                                        </div>
                                        <Button onClick={() => hostelRedirect(eachHostel.id)} className="book-button">
                                            Book Now
                                        </Button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="bg-dark-o">
                    <div className="main-container pt-5">
                        <div className="text-text home-heading text-center pb-5">Curated Experiences</div>
                        <div className="main-container">
                            <div className="new-heights-background">
                                <Card style={{ backgroundColor: "rgba(0,0,0,0.3)" }} className="card-width">
                                    <CardBody>
                                        <div style={{ fontSize: "1.5rem" }} className="text-text mb-2" >Ascend to New Heights</div>
                                        <div className="text-text mb-5 card-content">
                                            This experience offers stays at charming hostels set in breathtaking
                                            mountain locations, complete with guided hikes, cozy campfires, and the serenity
                                            only nature can provide.
                                        </div>
                                        <Button className="book-button">
                                            Book Now
                                        </Button>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="paradise-background mt-5">
                                <Card style={{ backgroundColor: "rgba(0,0,0,0.3)" }} className="card-width">
                                    <CardBody>
                                        <div style={{ fontSize: "1.5rem" }} className="text-text mb-2" >Dive Into Paradise</div>
                                        <div className="text-text mb-5 card-content">
                                            Whether you want to catch waves, soak up the sun, or dance the night away,
                                            this getaway is your ticket to a vibrant beachside adventure
                                        </div>
                                        <Button className="book-button">
                                            Book Now
                                        </Button>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="life-background mt-5">
                                <Card style={{ backgroundColor: "rgba(0,0,0,0.3)" }} className="card-width">
                                    <CardBody>
                                        <div style={{ fontSize: "1.5rem" }} className="text-text mb-2" >Celebrate Life</div>
                                        <div className="text-text mb-5 card-content">
                                            Enjoy exclusive access to themed mixers, hostel-hosted parties,
                                            and local events that promise to create unforgettable memories and friendships.
                                        </div>
                                        <Button className="book-button">
                                            Book Now
                                        </Button>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="row row-cols-lg-4 row-cols-1 mt-5 g-3 pb-5" >
                                <div className="col text-center">
                                    <div className="text-text mb-2 home-heading" >100+</div>
                                    <div className="text-text">Affordable Properties</div>
                                </div>
                                <div className="col text-center">
                                    <div className="text-text mb-2 home-heading" >85+</div>
                                    <div className="text-text">Successful mixers organised</div>
                                </div>
                                <div className="col text-center">
                                    <div className="text-text mb-2 home-heading" >20+</div>
                                    <div className="text-text">Years of hospitality experience</div>
                                </div>
                                <div className="col text-center">
                                    <div className="text-text mb-2 home-heading" >30k</div>
                                    <div className="text-text">Happy Customers</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="text-dark home-heading text-center py-5 fw-bold">Why Choose us</div>
                    <div className="main-container">
                        <div className="row row-cols-lg-3 row-cols-1 g-3 mb-5">
                            <div className="col text-center">
                                <img src={Tickets} alt="hostel" className="mb-2" />
                                <div className="text-dark mb-2" style={{ fontSize: "1.5rem" }}>
                                    360 Degree View
                                </div>
                                <div className="text-dark">
                                    We provide you with a 360 view before you even leave your home
                                </div>
                            </div>
                            <div className="col text-center">
                                <img src={MapImage} alt="hostel" className="mb-2" />
                                <div className="text-dark mb-2" style={{ fontSize: "1.5rem" }}>
                                    100% hostel coverage
                                </div>
                                <div className="text-dark">
                                    We show you all the hostels in the vicinity with a range of more than 100+ Hostels on the platform
                                </div>
                            </div>
                            <div className="col text-center">
                                <img src={Luggage} alt="hostel" className="mb-2" />
                                <div className="text-dark mb-2" style={{ fontSize: "1.5rem" }}>
                                    Budget Friendly
                                </div>
                                <div className="text-dark">
                                    We ensure that costs are in check where ever you travel
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default LandingPage
